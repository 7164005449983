import '../../styles/components/MouseScroll.scss'

function MouseScroll() {
  return (
    <div>
      <div className="mouse-icon"></div>
      <div className="mouse-arrow"></div>
    </div>
  )
}

export default MouseScroll

export const experiences = [
  {
    date: 'Apr 2023 - Sep 2023',
    duration: '6 months',
    position: 'Software Engineer',
    company: 'Capgemini',
    company_logo: './icons/capgemini.png',
    location: 'Issy-les-Moulineaux, France',
    project:
      'Redesign of a collaborative learning and knowledge-sharing web application.',
    responsibilities: [
      'Analysis of requirements and technical solutions.',
      'Development of new features (Front-end and Back-end).',
      'Implementation of unit and end-to-end tests.',
      'Code documentation and participation in code reviews.',
    ],
    technologies: [
      'React.js',
      'Nest.js',
      'TypeScript',
      'styled-components',
      'TypeORM',
      'PostgreSQL',
      'Docker',
      'RabbitMQ',
      'Nx',
      'Jest',
      'Cypress',
      'GitLab',
      'Jira',
      'Postman',
      'DBeaver',
    ],
  },
  {
    date: 'Mar 2022 - Aug 2022',
    duration: '6 months',
    position: 'Full Stack Developer',
    company: 'IBITEAM',
    company_logo: './icons/ibiteam.png',
    location: 'Mantes-la-Jolie, France',
    project: 'Migration of an English vocabulary learning web application.',
    responsibilities: [
      'Participation in the analysis and planning of the migration.',
      'Development of Front-end and Back-end user stories.',
      'Contribution to evolutionary and corrective maintenance.',
      'Execution of unit tests in the continuous integration (CI) pipeline.',
    ],
    technologies: [
      'Angular',
      'TypeScript',
      'RxJS',
      'Java',
      'Spring boot',
      'HTML5',
      'Sass',
      'MongoDB',
      'GitLab',
      'Jira',
      'Postman',
      'VS Code',
    ],
  },
  {
    date: 'Jun 2021 - Sep 2021',
    duration: '4 months',
    position: 'Full Stack Developer',
    company: 'IBITEAM',
    company_logo: './icons/ibiteam.png',
    location: 'Mantes-la-Jolie, France',
    project:
      'Development of GuideWay, a hybrid mobile mapping and route management application.',
    responsibilities: [
      'Design of solutions and creation of mockups.',
      'Development of reusable components for the frontend.',
      'Implementation of best practices for testing and security.',
      'Implementation of a REST API for client-server interactions.',
    ],
    technologies: [
      'React Native',
      'JavaScript',
      'StyleSheet',
      'C#',
      '.NET Core',
      'Entity Framework',
      'SQL Server',
      'Redux',
      'Github',
      'GitKraken',
      'Trello',
      'Swagger',
      'Postman',
      'Figma',
      'UML',
    ],
  },
]
